<script setup lang="ts">
const { notifications, removeOne } = useNotifications();
</script>
<template>
  <div
    data-testid="notification-container"
    class="fixed top-5 left-1/2 transform -translate-x-1/2 z-2000 max-h-fit"
  >
    <LayoutNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @click:close="removeOne(notification.id)"
    />
  </div>
</template>
